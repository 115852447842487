<template>
  <div class="md-card">
    <div class="md-card-header">
      <div class="card-icon">
        <i class="fa fa-globe"></i>
      </div>
      <h4 class="title">{{ title }}</h4>
    </div>
    <div class="md-card-content">
      <div class="md-layout-item">
        <doctor-form></doctor-form>
      </div>
    </div>
  </div>
</template>
<script>
import DoctorForm from "./DoctorForm.vue";
export default {
  inject: ["manageDoctor"],
  data() {
    return {
      title: "",
    };
  },
  created() {
    this.getTitle();
  },
  components: {
    "doctor-form": DoctorForm,
  },
  watch: {
    "manageDoctor.userType": function () {
      this.getTitle();
    },
  },
  methods: {
    getTitle() {
      if (this.manageDoctor.page == "Approval")
        this.title = this.manageDoctor.userType + " Approval";
      else if (this.manageDoctor.id)
        this.title = "Edit " + this.manageDoctor.userType;
      else this.title = "New " + this.manageDoctor.userType;
    },
  },
};
</script>
